@import url("https://fonts.googleapis.com/css2?family=Kodchasan:wght@300;500;700&family=Montserrat:wght@500&display=swap");

body {
  font-family: "Montserrat", sans-serif;
}

.main-logo {
  display: block;
  margin: 0 auto;
  padding-top: 25px;
}
