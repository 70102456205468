.icon {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 150px;
  height: 150px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #e5e5e5;
}

.icon-title {
  font-size: 18px;
}

.icon-sub-title {
  font-size: 14px;
}

.sub-header {
  font-size: 20px;
}
