@import url(https://fonts.googleapis.com/css2?family=Kodchasan:wght@300;500;700&family=Montserrat:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: "Montserrat", sans-serif;
}

.main-logo {
  display: block;
  margin: 0 auto;
  padding-top: 25px;
}

.icon {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 150px;
  height: 150px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #e5e5e5;
}

.icon-title {
  font-size: 18px;
}

.icon-sub-title {
  font-size: 14px;
}

.sub-header {
  font-size: 20px;
}


